import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams, Link, useLocation } from "react-router-dom";
const PersonalStudents = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [profileList_m, setProfileList_m] = useState([]);

    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);

    const [profileListFull, setProfileListFull] = useState([]);
    const [currentYearList, setCurrentYearList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);

    const [educationsList, setEducations] = useState([]);

    const companyId=sessionStorage.getItem("logCompanyId");
    var fspagenum = queryParams.get('pageid');
    var FileSaver = require('file-saver');
    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;
    function strCatArr(arr){
        //console.log(arr);
        if(Array.isArray(arr)){
            arr = arr.join(", ");
            //console.log(arr);
          return arr;
        }else{
          return "";
        }
      }
      const cardsloop = [1,2,3,4,5,6,7,8,9,10];
    useEffect(() => {
        $('.doublecards').show();
        if(companyId == null || companyId == "null"){
            //navigate('/login');
            navigate('/Home');
        }
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'companyStudentsList'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        fspagenum = '1';
                    }
                    else {
                        fspagenum = dev_poff_m; 
                    } 
                }
                else{
                    fspagenum = dev_poff; 
                }
            } else {
                fspagenum = '1';
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('company_profile');
                sessionStorage.removeItem('school_list');
                sessionStorage.removeItem('class_year');
            }
        } else {
            //json empty
            fspagenum = '1';
            sessionStorage.removeItem('master_industry');
            sessionStorage.removeItem('master_jobtype');
            sessionStorage.removeItem('company_profile');
            sessionStorage.removeItem('school_list');
            sessionStorage.removeItem('class_year');
        }
    }

        if(fspagenum == null || fspagenum == ''){
            var web_searchtxt = session_pageobj['search_text'];
            var web_classyear = session_pageobj['classYear'];
            var web_industry = session_pageobj['industry'];
            var web_school = session_pageobj['school'];
            var web_education = session_pageobj['educationLevel'];
            if(web_searchtxt == '' && web_classyear == '' && web_industry == '' && web_school == '' && web_education == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_classyear = session_pageobj['classYear_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_school = session_pageobj['school_m'];
            var mob_education = session_pageobj['educationLevel_m'];
            if(mob_searchtxt == '' && mob_classyear == '' && mob_industry == '' && mob_school == '' && mob_education == ''){
                var mobfilters = 0;
            }
            //console.log('here');
           // alert("set")
            // localStorage.setItem('fs_industry_selected', '');
            // localStorage.setItem('fs_school_selected', '');
            // localStorage.setItem('fs_year_selected', '');
            // localStorage.setItem('fs_search_text', '');

            // localStorage.setItem('fs_industry_selected_m', '');
            // localStorage.setItem('fs_school_selected_m', '');
            // localStorage.setItem('fs_year_selected_m', '');
            // localStorage.setItem('fs_search_text_m', '');

            // sessionStorage.setItem('fs_industry_selected', '');
            // sessionStorage.setItem('fs_school_selected', '');
            // sessionStorage.setItem('fs_year_selected', '');
            // sessionStorage.setItem('fs_search_text', '');

            // sessionStorage.setItem('fs_industry_selected_m', '');
            // sessionStorage.setItem('fs_school_selected_m', '');
            // sessionStorage.setItem('fs_year_selected_m', '');
            // sessionStorage.setItem('fs_search_text_m', '');
            // sessionStorage.setItem('fs_page_offset', '');
            // sessionStorage.setItem('fs_page_offset_m', '');
            if(webfilters == 0 && mobfilters == 0){
            var jsonData = {
                "companyStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": "",
                  "pagination_val": '',
                  "educationLevel":'',
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m": ''
                }
              };

            var jsondataformatted = JSON.stringify(jsonData);
            sessionStorage.setItem('pagejsonObject', jsondataformatted);
        }

    }
    //getServiceEdicationInfo();
        $('#phone').usPhoneFormat();
        // getProfileInfo();
        // getIndustryData();
        // getJobType();
        // getSchoolList();
        // getCurrentYear();

        var companyprofileInfo = sessionStorage.getItem('company_profile');
            if(companyprofileInfo == null || companyprofileInfo == ''){
                getServiceProfileInfo();
            }
            else {
                getLocalProfileInfo();
            }

            var ind_master = sessionStorage.getItem('master_industry');
            if(ind_master == null || ind_master == ''){
                getServiceIndustryData();
            }
            else {
                getLocalIndustryData();
            }

            var jobtype_master = sessionStorage.getItem('master_jobtype');
            if(jobtype_master == null || jobtype_master == ''){
                getServiceJobType();
            }
            else {
                getLocalJobType();
            }

            var schools_master = sessionStorage.getItem('school_list');
            if(schools_master == null || schools_master == ''){
                getServiceSchoolList();
            }
            else {
                getLocalSchoolList();
            }

            console.log(session_pageobj['educationLevel']);
            var education_master = sessionStorage.getItem('master_educations');
            if(education_master == null || education_master == ''){
                getServiceEdicationInfo();
            }
            else {
                getLocalEdicationInfo();
            }

            

            getCurrentYear();
            

               // var education_master = session_pageobj['educationLevel'];
                //$("#education").val(education_master);
               // $('select[name="educationselection"]').val(education_master);

        //var education_master_m = session_pageobj['educationLevel_m'];
        //$("#education_m").val(education_master_m);
        //$('select[name="educationselection_m"]').val(education_master_m);
        

        //var t_fs_search_text = localStorage.getItem('fs_search_text');
        var t_fs_search_text = session_pageobj['search_text'];
        $(".searchKeyInput").val(t_fs_search_text);

        //var t_fs_search_text_m = localStorage.getItem('fs_search_text_m');
        var t_fs_search_text_m = session_pageobj['search_text_m'];
        $(".searchKeyInput_m").val(t_fs_search_text_m);

        $( document ).on( "click", ".searchContent", function() {
            filterContent(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "change", ".searchKeyInput", function() {
            filterContent(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "click", ".searchContent_m", function() {
            filterContent_m(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "change", ".searchKeyInput_m", function() {
            filterContent_m(ITEMS_PER_PAGE,'1','1','0');
         });

         $(".paginationContent").hide();
         setTimeout(() => {
            if (fspagenum != null && fspagenum != "") {
                    // var web_ind = localStorage.getItem('fs_industry_selected');
                    // var web_sch = localStorage.getItem('fs_school_selected');
                    // var web_yer = localStorage.getItem('fs_year_selected');
                    // var web_src = localStorage.getItem('fs_search_text');
                    var web_ind = session_pageobj['industry'];
                    var web_sch = session_pageobj['school'];
                    var web_yer = session_pageobj['classYear'];
                    var web_src = session_pageobj['search_text'];
                    var web_edu = session_pageobj['educationLevel'];
                    var pageoffset = session_pageobj['pagination_val'];
                    
                    if (web_ind != "" || web_sch != '' || web_yer != '' || web_src != '' || pageoffset != '' || web_edu != ''){
                        setTimeout(() => {
                            filterContent(ITEMS_PER_PAGE, fspagenum, '0','0');
                           // filterContent_m(ITEMS_PER_PAGE, fspagenum, '0');
                        }, 500);
                    }
                    else {
                        // var mob_ind = localStorage.getItem('fs_industry_selected_m');
                        // var mob_sch = localStorage.getItem('fs_school_selected_m');
                        // var mob_yer = localStorage.getItem('fs_year_selected_m');
                        // var mob_src = localStorage.getItem('fs_search_text_m');
                        var mob_ind = session_pageobj['industry_m'];
                        var mob_sch = session_pageobj['school_m'];
                        var mob_yer = session_pageobj['classYear_m'];
                        var mob_src = session_pageobj['search_text_m'];
                        var pageoffset_m = session_pageobj['pagination_val_m'];
                        var mob_edu = session_pageobj['educationLevel_m'];

                        if (mob_ind != "" || mob_sch != '' || mob_yer != '' || mob_src != '' || pageoffset_m != '' || mob_edu != ''){
                            setTimeout(() => {
                               // filterContent(ITEMS_PER_PAGE, fspagenum, '0');
                                filterContent_m(ITEMS_PER_PAGE, fspagenum, '0','0');
                            }, 500);
                        }
                        else {
                            filterContent(ITEMS_PER_PAGE, fspagenum, '0','0');
                            filterContent_m(ITEMS_PER_PAGE, fspagenum, '0','0');
                        }
                    }
            }else{
                filterContent(ITEMS_PER_PAGE, '1', '0','0');
                filterContent_m(ITEMS_PER_PAGE, '1', '0','0');
            }

        }, 500);

    }, []);

    const getServiceEdicationInfo = () => {
        Services.getEducation().then((res) => {
            //console.log("getEducation data=" + JSON.stringify(res.data));
            var serviceresponse = res.data.content;
            serviceresponse = serviceresponse.filter(function (elem) {
                return elem.name != 'PhD';
              });
            setEducations(serviceresponse);
            
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_educations', storeval);

        });

    }

    const getLocalEdicationInfo = () => {
        console.log(session_pageobj['educationLevel']);
        var localeducationval = sessionStorage.getItem('master_educations');
        var tempeducaions = JSON.parse(localeducationval);
        setEducations(tempeducaions);
        loadSelectedEducation();
    }

    const loadSelectedEducation = () => {
        setTimeout(() => {
            //var loc_jobtypeval = localStorage.getItem('pp_jobtype_selected');
            var loc_educationval = session_pageobj['educationLevel'];
            console.log(loc_educationval);
            jobeducationSelCheckLS(loc_educationval);

            //var loc_jobtypeval_m = localStorage.getItem('pp_jobtype_selected_m');
            var loc_educationval_m = session_pageobj['educationLevel_m'];
            jobeducationSelCheckLS_m(loc_educationval_m);
        }, 500);
    }

    function jobeducationSelCheckLS(local_educationarray){
        if(local_educationarray != undefined || local_educationarray != null){
            
            $('select[name="educationselection"]').val(local_educationarray);
            
        }
    }

    function jobeducationSelCheckLS_m(local_educationarray_m){
        if(local_educationarray_m != undefined || local_educationarray_m != null){
            
            $('select[name="educationselection_m"]').val(local_educationarray_m);
            
        }
    }


    function getServiceProfileInfo() {
        Services.getCompanyProfile(companyId).then((res) => {
          setCategoriesSelected(res.data.companyCategory);
          var companyCatIn = res.data.companyCategory;
          var serviceresponse = res.data.companyCategory;
          var storeval = JSON.stringify(serviceresponse);
          sessionStorage.setItem('company_profile', storeval);
        });
      }

      function getLocalProfileInfo() {
        var localindustryval = sessionStorage.getItem('company_profile');
        localindustryval = JSON.parse(localindustryval);
        setCategoriesSelected(localindustryval);
    }

      const handlePageChange2 = (offset,triggerevent) => {
        filterContent(ITEMS_PER_PAGE,offset, '0','1');
      };

      const handlePageChange2_m = (offset,triggerevent) => {
        filterContent_m(ITEMS_PER_PAGE,offset, '0','1');
      };


      const filterContent = (ITEMS_PER_PAGE,pageNumber, setlcstorage, positiontop) => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });

        var schoolIdArray = [];
        $.each($("input[name='schoolId']:checked"), function(){
            schoolIdArray.push($(this).val());
        });

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var searchkey = $('.searchKeyInput').val();

        var educationId = $('select[name="educationselection"]').val();

        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray;
            }

            var school_val2 = '';
            if (schoolIdArray != '') {
                //school_val2 = JSON.parse(schoolIdArray);
                school_val2 = schoolIdArray;
            }

            var year_val2 = '';
            if (schoolYearArray != '') {
                //year_val2 = JSON.parse(schoolYearArray);
                year_val2 = schoolYearArray;
            }

            // localStorage.setItem('fs_industry_selected', industries_val2);
            // localStorage.setItem('fs_school_selected', school_val2);
            // localStorage.setItem('fs_year_selected', year_val2);
            // localStorage.setItem('fs_search_text', searchkey);
            // sessionStorage.setItem('fs_industry_selected', industries_val2);
            // sessionStorage.setItem('fs_school_selected', school_val2);
            // sessionStorage.setItem('fs_year_selected', year_val2);
            // sessionStorage.setItem('fs_search_text', searchkey);

        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "companyStudentsList": {
                  "industry": categoriesArray,
                  "school": schoolIdArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "pagination_val": pageNumber,
                  "educationLevel" : educationId,
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : ''
                }
              };
        } else {
            var jsonData = {
                "companyStudentsList": {
                  "industry": categoriesArray,
                  "school": schoolIdArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "educationLevel" : educationId,
                  "pagination_val": '',
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : ''
                }
              };
        }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
// ****** only getthe students with jobtype fulltime, parttime, internship as client requested..
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": ["3", "2", "1"],
            "companyUser":"yes",
            "educationId":educationId,
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $('.doublecards').show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems(res.totalElements);
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNumber);

                    var contAr = res.content;
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    setProfileList(contAr);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
                    //filterContent_full();
               }


            });

            $('.doublecards').hide();

    }

    const filterContent_m = (ITEMS_PER_PAGE,pageNumber, setlcstorage, positiontop) => {
        var categoriesArray_m = [];

        $.each($("input[name='category_m']:checked"), function(){
            categoriesArray_m.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray_m = [];
        $.each($("input[name='schoolId_m']:checked"), function(){
            schoolIdArray_m.push($(this).val());
        });

        var schoolYearArray_m = [];
        $.each($("input[name='schoolYear_m']:checked"), function(){
            schoolYearArray_m.push($(this).val());
        });

        var searchkey_m = $('.searchKeyInput_m').val();
        var educationId_m = $('select[name="educationselection_m"]').val();
//alert(setlcstorage);
        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray_m != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray_m;
            }

            var school_val2 = '';
            if (schoolIdArray_m != '') {
              //  school_val2 = JSON.parse(schoolIdArray_m);
                school_val2 = schoolIdArray_m;
            }

            var year_val2 = '';
            if (schoolYearArray_m != '') {
               // year_val2 = JSON.parse(schoolYearArray_m);
                year_val2 = schoolYearArray_m;
            }

            // localStorage.setItem('fs_industry_selected_m', industries_val2);
            // localStorage.setItem('fs_school_selected_m', school_val2);
            // localStorage.setItem('fs_year_selected_m', year_val2);
            // localStorage.setItem('fs_search_text_m', searchkey_m);

            // sessionStorage.setItem('fs_industry_selected_m', industries_val2);
            // sessionStorage.setItem('fs_school_selected_m', school_val2);
            // sessionStorage.setItem('fs_year_selected_m', year_val2);
            // sessionStorage.setItem('fs_search_text_m', searchkey_m);
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "companyStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "educationLevel" : '',
                  "industry_m": categoriesArray_m,
                  "school_m": schoolIdArray_m,
                  "classYear_m": schoolYearArray_m,
                  "search_text_m": searchkey_m,
                  "pagination_val_m": pageNumber,
                  "educationLevel_m" : educationId_m
                }
              };
        } else {
            var jsonData = {
                "companyStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "educationLevel" : '',
                  "industry_m": categoriesArray_m,
                  "school_m": schoolIdArray_m,
                  "classYear_m": schoolYearArray_m,
                  "search_text_m": searchkey_m,
                  "pagination_val_m": '',
                  "educationLevel_m" : educationId_m
                }
              };
        }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput_m").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray_m,
            "classYear": schoolYearArray_m,
            "schoolId": schoolIdArray_m,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": ["3", "2", "1"],
            "companyUser":"yes",
            "educationId":educationId_m
            
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $('.doublecards').show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems_m(res.totalElements);
                    settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage_m(pageNumber);

                    var contAr = res.content;
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    setProfileList_m(contAr);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
                    //filterContent_full();
               }


            });

            $('.doublecards').hide();

    }

    const getCurrentYear = () =>{
        var currentYear = new Date().getFullYear()
        var years = [];
        years.push(currentYear);
        for(var i=1;i<7;i++){
          years.push(currentYear+i);
        }
        setCurrentYearList(years);
        setTimeout(() => {
            //var loc_yearval = localStorage.getItem('fs_year_selected');
            var loc_yearval = session_pageobj['classYear'];
            yearSelCheckLS(loc_yearval);
            if(loc_yearval != ""){
                //filterContent(ITEMS_PER_PAGE, pagenum, '0');
            }

            // var loc_yearval_m = localStorage.getItem('fs_year_selected_m');
            var loc_yearval_m = session_pageobj['classYear_m'];
            yearSelCheckLS_m(loc_yearval_m);
            if(loc_yearval_m != ""){
               // filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
            }
        }, 500);
      }

      function yearSelCheckLS(local_yeararray) {
        if(local_yeararray != undefined || local_yeararray != null){
            var local_yeararray = local_yeararray.toString();
            var schoolyearval = local_yeararray.split(",");
            for (var i = 0; i < schoolyearval.length; i++) {
                $('input[name="schoolYear"][value="' + schoolyearval[i] + '"]').prop("checked", true);
            }
        }
    }

    function yearSelCheckLS_m(local_yeararray_m) {
        if(local_yeararray_m != undefined || local_yeararray_m != null){
            var local_yeararray_m = local_yeararray_m.toString();
            var schoolyearval_m = local_yeararray_m.split(",");
            for (var i = 0; i < schoolyearval_m.length; i++) {
                $('input[name="schoolYear_m"][value="' + schoolyearval_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceSchoolList = () => {
        //alert("14");
      const requestObject={
          "siteId":process.env.React_App_SITE_ID,
          "pageNumber":'1',
          "pageSize":'500'
       }

       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   //'Authorization': "Bearer "+accessTokenIn
                   //'Authorization': accessTokenIn
                 },
         body: JSON.stringify(requestObject)
       };

       Services.getSchoolList(requestOptions).then((res) =>
       {
        
         console.log(JSON.stringify(res))
          if(res){
              console.log(JSON.stringify(res.data));
                 var contAr = res.content;
                 //setProfileList(res.content);
                 if(res){
                  setSchoolList(res);
                  
                 }
                var serviceresponse = res;
                var storetypeval = JSON.stringify(serviceresponse);
                sessionStorage.setItem('school_list', storetypeval);
            }
        //navigate('/thankyou');

       });
       loadSelectedSchools();
   }

   const getLocalSchoolList = () => {
        var localschoollist = sessionStorage.getItem('school_list');
        localschoollist = JSON.parse(localschoollist);
        setSchoolList(localschoollist);
        loadSelectedSchools();
}

   const loadSelectedSchools = () => {
    setTimeout(() => {
        //var loc_schoolval = localStorage.getItem('fs_school_selected');
        var loc_schoolval = session_pageobj['school'];
        schoolSelCheckLS(loc_schoolval);
        if(loc_schoolval != ""){
           // filterContent(ITEMS_PER_PAGE, pagenum, '0');
        }

        // var loc_schoolval_m = localStorage.getItem('fs_school_selected_m');
        var loc_schoolval_m = session_pageobj['school_m'];
        schoolSelCheckLS_m(loc_schoolval_m);
        if(loc_schoolval_m != ""){
           // filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
        }
    }, 500);
   }

function schoolSelCheckLS(local_schoolarray) {
    if(local_schoolarray != undefined || local_schoolarray != null){
        var local_schoolarray = local_schoolarray.toString();
        var schoolselval = local_schoolarray.split(",");
        for (var i = 0; i < schoolselval.length; i++) {
            $('input[name="schoolId"][value="' + schoolselval[i] + '"]').prop("checked", true);
        }
    }
}

function schoolSelCheckLS_m(local_schoolarray_m) {
    if(local_schoolarray_m != undefined || local_schoolarray_m != null){
        var local_schoolarray_m = local_schoolarray_m.toString();
        var schoolselval_m = local_schoolarray_m.split(",");
        for (var i = 0; i < schoolselval_m.length; i++) {
            $('input[name="schoolId_m"][value="' + schoolselval_m[i] + '"]').prop("checked", true);
        }
    }
}

function industrySelCheckLS(local_indarray) {
    if(local_indarray != undefined || local_indarray != null){
        var local_indarray = local_indarray.toString();
        var companyCatIn = local_indarray.split(",");
        for (var i = 0; i < companyCatIn.length; i++) {
            $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
        }
    }
}

function industrySelCheckLS_m(local_indarray_m) {
    if(local_indarray_m != undefined || local_indarray_m != null){
        var local_indarray_m = local_indarray_m.toString();
        var companyCatIn_m = local_indarray_m.split(",");
        for (var i = 0; i < companyCatIn_m.length; i++) {
            $('input[name="category_m"][value="' + companyCatIn_m[i] + '"]').prop("checked", true);
        }
    }
}

    const getServiceIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_industry', storeval);
        });
        loadSelectedindustries();
    }

    const loadSelectedindustries = () => {
        setTimeout(() => {
            var loc_industryval = session_pageobj['industry'];
            industrySelCheckLS(loc_industryval);
            if(loc_industryval != ""){
               // filterContent(ITEMS_PER_PAGE, pppagenum, '0');
            }

            var loc_industryval_m = session_pageobj['industry_m'];
            industrySelCheckLS_m(loc_industryval_m);
            if(loc_industryval != ""){
               // filterContent_m(ITEMS_PER_PAGE, pppagenum, '0');
            }
        }, 500);
    }

    const getLocalIndustryData = () => {
        var localindustryval = sessionStorage.getItem('master_industry');
        localindustryval = JSON.parse(localindustryval);
        setCategory(localindustryval);
        loadSelectedindustries();
    }

    const getServiceJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
            var serviceresponse = res.data.content;
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_jobtype', storetypeval);
        });
    }

    const getLocalJobType = () => {
        var localjobtypeval = sessionStorage.getItem('master_jobtype');
        var tempjobtypes = JSON.parse(localjobtypeval);
        setJobType(tempjobtypes);
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Student Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        {/*<span className="mb-3 text-center">
                                          <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span> */}
                                        
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search by Name"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>


                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">School District</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                schoolList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.schoolId} name="schoolId"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Graduating Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" id="schoolYear" type="checkbox" value={data} name="schoolYear"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                            <select name="educationselection" id="education" className="form-select searchContent">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                        <span class="mr-2" style={{minWidth:"65px"}}>Seeking:</span>
                                                        <span class="mb-0 clamp">{strCatArr(data.jobtypename_new)}</span>
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200 ">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School/College:</span>
                                                        <span>{(data.educationId == 3) ? 
                                                            (data.schoolId == 33) ? data.schoolDistrictOther : ((data.name =='' || data.name ==null) ? '--' : data.name) : 
                                                            (data.nameOfCollegeUniversityId == 22) ? data.nameOfCollegeUniversity : ((data.nameOfCollegeOrUniversity =='' || data.nameOfCollegeOrUniversity ==null) ? '--' : data.nameOfCollegeOrUniversity) }</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn ">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <Link to={`/studentProfileView?studentId=`+data.studentId+`&pageid=` + currentPage} class="reg-button m-auto d-block">View</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                  {/*  <span className="mb-3 text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span> */}
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput_m" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.catId} name="category_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">School District</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                schoolList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.schoolId} name="schoolId_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data} name="schoolYear_m"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                            <select name="educationselection_m" id="education_m" className="form-select searchContent_m">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-8 mb-4">
                                    <div class="two-grid-wrap">
                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                    {
                                       profileList_m.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <span class="mr-2" style={{minWidth:"65px"}}>Seeking:</span>
                                                            <span class="mb-0">{strCatArr(data.jobtypename_new)}</span>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200 ">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School/College:</span>
                                                        <span>
                                                            {(data.educationId == 3) ? 
                                                            (data.schoolId == 33) ? data.schoolDistrictOther : ((data.name =='' || data.name ==null) ? '--' : data.name) : 
                                                            (data.nameOfCollegeUniversityId == 22) ? data.nameOfCollegeUniversity : ((data.nameOfCollegeOrUniversity =='' || data.nameOfCollegeOrUniversity ==null) ? '--' : data.nameOfCollegeOrUniversity) }
                                                        </span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer d-flex align-items-center"  style={{maxWidth:"260px"}}>
                                                <Link to={`/studentProfileView?studentId=`+data.studentId+`&pageid=`+currentPage} class="reg-button  m-auto d-block">View</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems_m}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage_m}
                                        onPageChange={handlePageChange2_m}/>

                                       </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
        </>
    )
};

PersonalStudents.propTypes = {};

PersonalStudents.defaultProps = {};

export default PersonalStudents;
